import React, { useEffect, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";

import { useQueryParam, StringParam } from "use-query-params";

import BioContent from "./BioContent";

function BioPopup() {
  // used to set and read id QS
  const [name, setName] = useQueryParam("person", StringParam);

  // response from wp-json fetch
  const [postData, setPostData] = useState(null);

  const [error, setError] = useState(null);

  const [showContent, setShowContent] = useState(false);
  const popupRef = useRef(null);
  // add event listeners for all buttons on mount
  useEffect(() => {
    const handleClick = e => {
      const target = e.currentTarget;
      const parent = target.parentNode;
      const slug = parent.getAttribute("data-slug");

      setName(encodeURI(slug), "push");
    };

      // added 2 at end of class to disable button popup feature
    const items = document.querySelectorAll(".mcast-biolist-item__button2");
    items.forEach(item => {
      item.addEventListener("click", handleClick);
    });
  }, []);

  // if id is changed, fetch json and set postData
  useEffect(() => {
    if (name) {
      fetch(`/wp-json/wp/v2/team?slug[]=${name}`)
        .then(res => res.json())
        .then(json => {
          if (!json || !json.length) return;
          setPostData(json[0]);
        })
        .catch(err => {
          setError(err);
        });
    } else {
      setShowContent(false);
    }
  }, [name]);

  useEffect(() => {
    if (!postData) {
      setShowContent(false);
      return;
    }

    if (postData.slug === name) {
      setShowContent(true);
      return;
    }
  }, [postData, error]);

  return (
    <CSSTransition
      classNames="slide"
      timeout={450}
      in={Boolean(name)}
      appear
      mountOnEnter
      unmountOnExit
    >
      <div className="bio-popup" ref={popupRef}>
        <div className="bio-popup__inner">
          {error ? (
            <div className="bio-popup__error">
              <h2>404 Not Found</h2>
              <p>There was an issue with server request.</p>
              <a href="/">Go back</a>
            </div>
          ) : (
            <CSSTransition
              classNames="fadeUp"
              timeout={450}
              in={showContent}
              appear
              mountOnEnter
              unmountOnExit
            >
              <BioContent
                postData={postData}
                removeQuery={() => setName(null)}
                scrollTarget={popupRef}
              />
            </CSSTransition>
          )}
        </div>
      </div>
    </CSSTransition>
  );
}

export default BioPopup;
