import React, { useEffect, useRef } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";

export default BioContent;

function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

function useBodyLock(target) {
  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    if (target) {
      document.body.classList.add("no-scroll");
      disableBodyScroll(target.current);
    }
  }, [target]);

  return () => {
    document.body.classList.remove("no-scroll");
    enableBodyScroll(target.current);
  };
}

function BioContent({ postData, removeQuery, scrollTarget }) {
  const handleKeyDown = ({ key }) => {
    if (["27", "Escape"].includes(String(key))) {
      removeQuery();
    }
  };

  useEventListener("keydown", handleKeyDown);
  const removeLock = useBodyLock(scrollTarget);

  const handleBackClick = () => {
    removeLock();
    removeQuery();
  };

  const title = postData.title.rendered;
  const position = postData.acf.position;
  const linkedInURL = postData.acf.linkedin;

  return (
    <div className="bio-popup__content">
      <div className="bio-popup__bio">
        <button onClick={handleBackClick} className="bio-back-btn">
          <i className="fa fa-angle-left fa-2x"></i>
          <p>Back to About</p>
        </button>
        <div className="bio-popup__header">
          {title && <h2>{title}</h2>}
          {position && <h5>{position}</h5>}
          {linkedInURL && (
            <small>
              <a href={linkedInURL} target="_blank">
                <i className="fa fa-linkedin-square fa-2x"></i>
              </a>
            </small>
          )}
        </div>
        <div
          className="bio-popup__description"
          dangerouslySetInnerHTML={{
            __html: postData.content.rendered
          }}
        ></div>
      </div>
      <div
        className="bio-popup__image"
        style={{
          backgroundImage: `url(${postData.acf.large_image})`
        }}
      />
    </div>
  );
}
