import React from "react";
import ReactDOM from "react-dom";
import BioPopup from "./components/BioPopup";
import { QueryParamProvider } from "use-query-params";
import history from "./history";
function App() {
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  React.useEffect(() => {
    // listen for changes to the URL and force the app to re-render
    history.listen(() => {
      forceUpdate();
    });
  }, []);

  return <BioPopup history={history} />;
}

const root = document.getElementById("mcast-bio-popup-root");

if (root) {
  ReactDOM.render(
    <QueryParamProvider history={history}>
      <App />
    </QueryParamProvider>,
    root
  );
}
